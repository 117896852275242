// export { default } from 'components/VideoSupervision'

import Loader from 'components/Loader'
import { lazy, Suspense } from 'react'

const VideoSupervisionLazy = lazy(() => import('components/VideoSupervision'))

const isSSR = typeof window === 'undefined'

const VideoSupervisionWrapper = () => {
  return (
    <section className="section" style={{ paddingTop: '1.5rem' }}>
      {isSSR ? (
        <Loader />
      ) : (
        <Suspense fallback={<Loader />}>
          <VideoSupervisionLazy />
        </Suspense>
      )}
    </section>
  )
}

export default VideoSupervisionWrapper
