const Loader = () => (
  <>
    <div className="loadable is-loading"></div>
    <div className="is-flex is-fullwidth is-justify-content-center mb-4">
      <div>Loading...</div>
    </div>
  </>
)

export default Loader
